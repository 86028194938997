import "bootstrap"; // Bootstrap4
//jquery
//import $ from 'jquery';
// vegasスライダー
//import './plugins/vegas/vegas.min.js';
import "./plugins/jquery.bootstrap-autohidingnavbar.min.js"; // スクロールアップナビ
// import "./plugins/vivus.js"; // SVGアニメ用
import "./plugins/jquery.bgswitcher.js"; // 背景スライダー
import "./plugins/jquery_inview/jquery.inview.min.js"; // スクロールエフェクト02
import "lightbox2";
import "./myscript.js"; // 実行スクリプト
import "./index.scss";