jQuery(function () {
	/* ナビゲーション高さ分のpadding-topをbodyに設定 */
	let nav_height = jQuery('.navbar').outerHeight();
	let nav_size = nav_height + 'px';
	//alert( nav_size );
	jQuery('body').css('padding-top', nav_size);

	/* ページ内リンクをクリック時の.navbar-collapse非表示 */
	jQuery('.navbar-nav li a').click(function () {
		jQuery('.navbar-collapse').collapse('hide');
	});
});
/* スクロールアップナビ */
$('.fixed-top').autoHidingNavbar();
$(window).scroll(function () {
	var header = $('#g-nav');
	var scroll = $(document).scrollTop();
	if (scroll >= 100) {
		header.addClass('is-black');
	} else {
		header.removeClass('is-black');
	}
});
/* スクロールエフェクト-01 */
(function ($) {
	// 画面下からどの程度の位置でエフェクトするかの設定
	let EffectH = 200;
	// どのくらい動かすかの設定
	let EffectTop = 100;
	let EffectLeft = -500;
	let EffectRight = -500;
	let EffectName = -800;
	// エフェクト時間(ms)
	let EffectTime = 500;
	let EffectTimeColor = 1000;
	let EffectTimeName = 1000;
	// 事前準備として少し下げたところに透明にして配置。
	$('.my-effect').css({
		position: 'relative',
		left: 0,
		top: EffectTop,
		opacity: 0,
		transition: EffectTime + 'ms'
	});
	$('.my-effect-left').css({
		position: 'relative',
		left: EffectLeft,
		top: 0,
		opacity: 0,
		transition: EffectTime + 'ms'
	});
	$('.my-effect-right').css({
		position: 'relative',
		right: EffectRight,
		top: 0,
		opacity: 0,
		transition: EffectTime + 'ms'
	});
	$('.my-effect-color').css({
		background: '#315096',
		transition: EffectTimeColor + 'ms'
	});
	$('.my-effect-name').css({
		position: 'relative',
		left: EffectName,
		top: 0,
		opacity: 0,
		transition: EffectTimeName + 'ms cubic-bezier(0.175, 0.885, 0.32, 1.4)'
	});
	// スクロールイベント
	$(window).on('scroll load', function () {
		let scTop = $(this).scrollTop();
		let scBottom = scTop + $(this).height();
		let effectPos = scBottom - EffectH;
		$('.my-effect').each(function () {
			let thisPos = $(this).offset().top;
			if (thisPos < effectPos) {
				$(this).css({
					'opacity': 1,
					top: 0
				});
			}
		});
		$('.my-effect-left').each(function () {
			let thisPos = $(this).offset().top;
			if (thisPos < effectPos) {
				$(this).css({
					'opacity': 1,
					left: 0
				});
			}
		});
		$('.my-effect-right').each(function () {
			let thisPos = $(this).offset().top;
			if (thisPos < effectPos) {
				$(this).css({
					'opacity': 1,
					right: 0
				});
			}
		});
		$('.my-effect-color').each(function () {
			let thisPos = $(this).offset().top;
			if (thisPos < scTop + 450) {
				$(this).css({
					background: '#B31D23'
				});
			}
		});
		$('.my-effect-name').each(function () {
			let thisPos = $(this).offset().top;
			if (thisPos < effectPos) {
				$(this).css({
					'opacity': 1,
					left: 0
				});
			}
		});
	});
})(jQuery);

/* スクロールエフェクト02 */
/* 移動＋フェードイン表示 */
$(function () {
	$('.iv-up-fade').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
		if (isInView) {
			$(this).stop().addClass('mv-up-fade');
		} else {
			$(this).stop().removeClass('mv-up-fade');
		}
	});
});


/* Tooltip */
$(function () {
	$('[data-toggle="tooltip"]').tooltip()
});

/* lightbox */
lightbox.option({
	'resizeDuration': 200,
	'wrapAround': true,
	'fitImagesInViewport': false
});

// lightbox、IE対応
$(function () {
	$(".lb-img").click(function () {
		$('body').addClass("fixed")
	});
	$(".lightboxOverlay,.lb-close,.lightbox").click(function () {
		$('body').removeClass("fixed")
	});
});